import { z } from 'zod';

import { OTP_CODE_LENGTH_6, OTP_CODE_LENGTH_5 } from '@dock/common';

import validationLang from '../../lang';

const getOtpCodeSchema = (length: number) => ({
    otpCode: z.string().trim().length(length, validationLang.INVALID_OTP_CODE),
});

export const emailOtpCodeSchema = z.object(getOtpCodeSchema(OTP_CODE_LENGTH_6));
export const phoneOtpCodeSchema = z.object(getOtpCodeSchema(OTP_CODE_LENGTH_5));
