import { subYears } from 'date-fns';
import { z } from 'zod';

import validationLang from '../lang';

export enum WebIdFieldsNames {
    GENDER = 'gender',
    GIVEN_NAMES = 'given_names',
    SURNAMES = 'surnames',
    DATE_OF_BIRTH = 'date_of_birth',
    EMAIL_ADDRESS = 'email_address',
    ZIP = 'zip',
    CITY = 'city',
    COUNTRY = 'country',
}

enum Gender {
    MALE = 'Male',
    FEMALE = 'Female',
    DIVERSE = 'Diverse',
}

const utf8Regexp = /[a-z\u00C0-\u017F]+/gim;
const YEARS_TO_BE_ADULT = 18;
const MAX_USER_AGE = 80;
const maxDate = subYears(new Date(), YEARS_TO_BE_ADULT);
const minDate = subYears(new Date(), MAX_USER_AGE);

const possibleValidation = [
    {
        fieldName: WebIdFieldsNames.GENDER,
        validation: z.nativeEnum(Gender, {
            errorMap: () => ({ message: validationLang.REQUIRED }),
        }),
    },
    {
        fieldName: WebIdFieldsNames.GIVEN_NAMES,
        validation: z
            .string()
            .trim()
            .nonempty(validationLang.REQUIRED)
            .regex(utf8Regexp, validationLang.UTF8),
    },
    {
        fieldName: WebIdFieldsNames.SURNAMES,
        validation: z
            .string()
            .nonempty(validationLang.REQUIRED)
            .regex(utf8Regexp, validationLang.UTF8),
    },
    {
        fieldName: WebIdFieldsNames.DATE_OF_BIRTH,
        validation: z
            .date({ required_error: validationLang.REQUIRED })
            .max(maxDate, validationLang.INVALID_BIRTHDAY_MAX_DATE)
            .min(minDate, validationLang.INVALID_BIRTHDAY_MIN_DATE),
    },
    {
        fieldName: WebIdFieldsNames.EMAIL_ADDRESS,
        validation: z.string().email(),
    },
    {
        fieldName: WebIdFieldsNames.ZIP,
        validation: z.string().trim().nonempty(validationLang.REQUIRED),
    },
    {
        fieldName: WebIdFieldsNames.CITY,
        validation: z
            .string()
            .trim()
            .nonempty(validationLang.REQUIRED)
            .regex(utf8Regexp, validationLang.UTF8),
    },
    {
        fieldName: WebIdFieldsNames.COUNTRY,
        validation: z.string().trim().nonempty(validationLang.REQUIRED),
    },
];

const getValidValidationFields = (fields: WebIdFieldsNames[]) =>
    possibleValidation.filter(({ fieldName }) => fields.some((name) => name === fieldName));

export const getPersonalDetailsSchema = (fields: WebIdFieldsNames[]) =>
    z.object({
        ...Object.fromEntries(
            getValidValidationFields(fields).map(({ fieldName, validation }) => [
                fieldName,
                validation,
            ])
        ),
    });
